import { ApplicationInsights } from "@microsoft/applicationinsights-web";
import { configValue } from "@/services/config";
import { generateW3CId } from "@microsoft/applicationinsights-core-js";

let _instance;

export const VUE_APPLICATION_INSIGHTS = Symbol("VUE_APPLICATION_INSIGHTS");

function createApplicationInstanceVuePlugin(applicationInsights) {
  return {
    install: (app) => {
      app.provide(VUE_APPLICATION_INSIGHTS, applicationInsights);
    },
  };
}

function resolveConfiguration() {
  return {
    applicationVersion: configValue("VUE_APP_APPLICATION_VERSION"),
    cloudRoleName: configValue("VUE_APP_AZURE_APP_INSIGHT_APP"),
    instrumentationKey: configValue("VUE_APP_AZURE_APP_INSIGHT_ID"),
  };
}

export function createApplicationInsights(store, router) {
  if (_instance) {
    throw new Error("ApplicationInsights already initialized");
  }

  const config = resolveConfiguration();

  _instance = new ApplicationInsights({
    config: {
      instrumentationKey: config.instrumentationKey,
      enableAjaxErrorStatusText: true,
      enableAutoRouteTracking: false,
      autoTrackPageVisitTime: true,
      enableCorsCorrelation: true,
      correlationHeaderExcludedDomains: ["googleapis.com"],
      enableRequestHeaderTracking: true,
      enableResponseHeaderTracking: true,
    },
  });

  _instance.loadAppInsights();
  _instance.addTelemetryInitializer(setupTelemetry(store, config));
  setupPageTracking(_instance, router, config.cloudRoleName);

  return {
    applicationInsights: _instance,
    applicationInsightsPlugin: createApplicationInstanceVuePlugin(_instance),
  };
}

function setupPageTracking(applicationInsights, router, cloudRoleName) {
  router.beforeEach((route, from, next) => {
    applicationInsights.context.telemetryTrace.traceID = generateW3CId();
    applicationInsights.context.telemetryTrace.name = route.name ?? undefined;
    applicationInsights.startTrackPage(cloudRoleName + " / " + route.name);
    next();
  });

  router.afterEach((route) => {
    const name = cloudRoleName + " / " + route.name;
    applicationInsights.stopTrackPage(name, location.href);
    applicationInsights.flush();
  });
}

const resolveMainRole = (roles) => {
  if (roles.length === 0) {
    return "anonymous";
  }
  if (roles.includes("se.eaztimate.ifsplanering.planner")) {
    return "planner";
  }
  return "user";
};

function setupTelemetry(store, config) {
  return function (envelope) {
    const userRoles = store.state.userData.roles || [];
    const user = store.state.userData.user?.profile?.sub;
    const companyId = store.state.userData.companyid;
    const userProfession = resolveMainRole(userRoles);

    envelope.tags["ai.cloud.role"] = config.cloudRoleName;
    envelope.tags["ai.application.ver"] = config.applicationVersion;

    envelope.data["userProfession"] = userProfession;
    if (companyId) {
      envelope.data["companyId"] =
        companyId === 1 ? "01" : companyId.toString();
    }

    if (user) {
      envelope.tags["ai.user.authUserId"] = user;
    }
  };
}

export function getApplicationInsightsInstance() {
  return _instance;
}
