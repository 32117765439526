import { createApp } from "vue";
import router from "@/router";
import store from "@/services/store";
import { createApplicationInsights } from "@/services/application-insights/application-insight";
import { createGlobalErrorHandlerPlugin } from "./services/application-insights/global-error-handler-plugin";
import App from "@/views/App.vue";

const app = createApp(App);
app.use(store);
app.use(router);

const { applicationInsightsPlugin, applicationInsights } =
  createApplicationInsights(store, router);
app.use(applicationInsightsPlugin);

if (import.meta.env.PROD) {
  app.use(createGlobalErrorHandlerPlugin(), { applicationInsights });
}

app.mount("#app");
