export function translateState(status) {
  return workOrderStateTranslation[status] ?? "Okänd";
}

export const workOrderState = {
  Unknown: "Unknown",
  Registered: "Registered",
  InProgress: "InProgress",
  WaitingForVerification: "WaitingForVerification",
  WorkDone: "WorkDone",
  ReadyForInvoicing: "ReadyForInvoicing",
  Invoiced: "Invoiced",
  Finished: "Finished",
  Deleted: "Deleted",
  Cancelled: "Cancelled",
};

export const workOrderStateTranslation = {
  Unknown: "Okänd",
  Registered: "Registrerad",
  InProgress: "Pågående",
  WaitingForVerification: "Inväntar efterkontroll",
  WorkDone: "Utförd",
  ReadyForInvoicing: "Redo för fakturering",
  Invoiced: "Fakturerad",
  Finished: "Avslutad",
  Deleted: "Borttagen",
  Cancelled: "Avbruten",
};
