import Vuex from "vuex";
import oidcmgr from "@/services/oidcmgr";
import ajax from "@/services/ajax";
import VuexPersistence from "vuex-persist";
import { getApplicationInsightsInstance } from "@/services/application-insights/application-insight";

const initalUserData = (isInitialized) => ({
  isInitialized: isInitialized,
  isAuthenticated: false,
  user: null,
  isifsplanner: null,
  roles: [],
  signature: null,
  companyid: null,
  orgcode: null,
});

const store = new Vuex.Store({
  state: {
    // data from the users profile
    userData: initalUserData(false),

    // settings persisted to localStorage
    userSettings: {
      weekends: true,
      weeknumbers: false,
      statsuall: false,
      filterext: false, // if true, "Filter Avd" also includes where signature belongs to avd
      search: {
        companyid: null, // note: this companyid and orgcodes is prefered over using the userData items, since these are the last used ones
        orgcode: null,
        status: null,
        free: null,
        signature: null, // note: used to persist mobile filter
        state: null,
      },
      filter: {
        orgcode: null,
        free: null,
      },
      signature: null, // selected signatures in the 'Personal' view
      dayGridNDays: 7,
      ezTimelineNDays: 7,
    },
  },
  plugins: [
    new VuexPersistence({
      key: "settings",
      storage: window.localStorage,
      reducer: (state) => ({ userSettings: state.userSettings }),
    }).plugin,
  ],
  mutations: {
    userDataInit(state, userData) {
      state.userData = userData;
    },
    userDataClear(state) {
      state.userData = initalUserData(false);
    },
    userSettingSet(state, [name, value]) {
      const applicationInsights = getApplicationInsightsInstance();
      applicationInsights.trackEvent({
        name: "User Setting Updated",
        properties: {
          eventCategory: "User",
          settingName: name,
          settingValue: value,
        },
      });
      state.userSettings[name] = value;
    },
    userSettingSearchSet(state, [name, value]) {
      state.userSettings.search[name] = value;
    },
    userSettingFilterSet(state, [name, value]) {
      state.userSettings.filter[name] = value;
    },
  },
  actions: {
    async userInit({ state, commit, dispatch }) {
      // userInit is called on every route to avoid looking up the user from the store we can return fast
      //   - logout will be handled either manually or by the session monitor
      //   - tokens will be handled by the signinSilent
      // if we start to see failures with expired access tokens we could consider:
      //   - getting the oidc token before sending ajax requests
      //   - removing this early return to make the state more similar
      if (state.userData.isInitialized) {
        return;
      }

      try {
        const user = await oidcmgr.getUser();

        if (user != null && !user.expired) {
          // The oidc user is authenticated and the token is valid
          await dispatch("userUpdate", user);
        } else {
          // User is initialized but not authenticated
          commit("userDataInit", initalUserData(true));
        }
      } catch {
        await dispatch("userLogout");
      }
    },
    async userUpdate({ commit, dispatch }, user) {
      try {
        // We cannot get all the data from claims since company might not be updated in eaztimate
        const response = await ajax(
          { method: "get", url: "{ezip-api}/security/getuserdata" },
          user,
        );

        const data = response.data;

        commit("userDataInit", {
          isInitialized: true,
          isAuthenticated: true,
          user: user,
          isifsplanner: data.isifsplanner,
          roles: data.roles,
          signature: data.signature,
          companyid: data.companyid,
          orgcode: data.orgcode,
        });
      } catch {
        await dispatch("userLogout");
      }
    },
    async userLogin(_, path) {
      await oidcmgr.signinRedirect({ state: path });
    },
    async userLogout() {
      await oidcmgr.signoutRedirect();
    },
  },
});

export default store;
