import { SeverityLevel } from "@microsoft/applicationinsights-web";

export function createGlobalErrorHandlerPlugin() {
  return {
    install(app, options) {
      app.config.errorHandler = (error) => {
        if (!options) {
          return;
        }
        if (error instanceof Error) {
          options.applicationInsights.trackException({
            exception: error,
            severityLevel: SeverityLevel.Error,
          });
        }
      };
    },
  };
}
