<!-- eslint-disable prettier/prettier -->
<template>
  <div id="approot" class="container-fluid">
    <nav class="navbar navbar-expand fixed-top navbar-eaztimate">
      <svg
        id="Layer_1"
        version="1.1"
        xmlns="http://www.w3.org/2000/svg"
        xmlns:xlink="http://www.w3.org/1999/xlink"
        x="0px"
        y="0px"
        viewBox="0 0 24 24"
        style="enable-background: new 0 0 24 24; width: 24px; height: 24px"
        xml:space="preserve"
      >
        <g id="Ez">
          <path
            class="st0"
            d="
M12.24,1.12c6.14,0,11.13,4.99,11.13,11.14c0,6.14-4.99,11.14-11.13,11.14c-6.14,0-11.14-5-11.14-11.14
C1.11,6.11,6.1,1.12,12.24,1.12 M12.24,21.98c5.36,0,9.72-4.36,9.72-9.72c0-5.36-4.36-9.72-9.72-9.72c-5.36,0-9.72,4.36-9.72,9.72
C2.52,17.61,6.88,21.98,12.24,21.98"
          />
          <path
            class="st0"
            d="M18.47,12.84c0,3.44-2.79,6.23-6.23,6.23c-3.44,0-6.23-2.79-6.23-6.23H18.47z"
          />
        </g>
      </svg>
      <a class="navbar-brand" href="#">Eaztimate IFS Planner</a>

      <div
        id="navbar"
        class="collapse navbar-collapse justify-content-end"
        :class="{ hide: !userData.isAuthenticated }"
      >
        <ul class="navbar-nav">
          <li class="nav-item dropdown">
            <a
              class="nav-link dropdown-toggle"
              href="#"
              data-toggle="dropdown"
              aria-haspopup="true"
              aria-expanded="false"
              >{{ userData.user ? userData.user.profile.name : "" }} ({{
                userData.signature
              }})</a
            >
            <div class="dropdown-menu dropdown-menu-right">
              <a
                v-if="['start'].indexOf($route.name) > -1"
                id="careditorbutton"
                class="dropdown-item"
                @click="openCarEditor()"
                ><span class="fa fa-car"></span>Hantera bilar</a
              >
              <a
                v-if="['start'].indexOf($route.name) > -1"
                id="signatureawaybutton"
                class="dropdown-item"
                @click="openSignatureAwayEditor()"
                ><span class="fa fa-user"></span>Hantera frånvaro</a
              >
              <a
                v-if="['start'].indexOf($route.name) > -1"
                id="desktoptomobilebutton"
                class="dropdown-item"
                @click="openMobileView()"
                ><span class="fa fa-mobile"></span>Mobilvy</a
              >
              <form
                v-if="['map'].indexOf($route.name) > -1"
                autocomplete="off"
                class="navbar-form pl-4 pr-4 pt-3 pb-3"
              >
                <div class="row">
                  <div class="col">
                    <div class="form-group" style="position: relative">
                      <div style="position: absolute; right: 0; top: 0">
                        <span id="filtercount" class="mr-1"></span
                        ><span
                          id="filtercountloader"
                          class="fa fa-spinner fa-pulse"
                          style="display: none"
                        ></span>
                      </div>
                      <label>Företag</label>
                      <select id="filtercompanyid" class="form-control">
                        <option></option>
                      </select>
                    </div>
                    <div class="form-group">
                      <label>Avdelning</label>
                      <select
                        id="filterorgcode"
                        class="form-control"
                        size="1"
                        multiple
                      ></select>
                    </div>
                    <div
                      class="form-group filteradvanced"
                      style="display: none"
                    >
                      <label>Statsu</label>
                      <select id="filterstatsu" class="form-control">
                        <option value="-1">Alla (ink. AO Avslutad)</option>
                        <option value="0">Endast Aktiva</option>
                      </select>
                    </div>
                    <div
                      class="form-group filteradvanced"
                      style="display: none"
                    >
                      <label>Projekt</label>
                      <input
                        id="filterprojectno"
                        type="text"
                        class="form-control"
                      />
                    </div>
                    <div
                      class="form-group filteradvanced"
                      style="display: none"
                    >
                      <label>Utförare / Arbetsledare / Handläggare / mfl</label>
                      <select
                        id="filtersignature"
                        class="form-control"
                        size="1"
                        multiple
                      ></select>
                    </div>
                    <div
                      class="form-group filteradvanced"
                      style="display: none"
                    >
                      <label>Typ av utförare</label>
                      <select
                        id="filtersignaturetype"
                        class="form-control"
                        size="1"
                        multiple
                      >
                        <option value="1">Utförare</option>
                        <option value="2">Huvudutförare</option>
                        <option value="3">Arbetsledare</option>
                        <option value="4">Handläggare</option>
                      </select>
                    </div>
                    <div
                      class="form-group filteradvanced"
                      style="display: none"
                    >
                      <label>Specialfilter</label>
                      <select
                        id="filtermisc"
                        class="form-control"
                        size="1"
                        multiple
                      >
                        <option value="10">Projekt: Ja</option>
                        <option value="11">Projekt: Nej</option>
                        <option value="50">Dold: Ja</option>
                        <option value="51">Dold: Nej (standard)</option>
                      </select>
                    </div>
                    <div class="form-group">
                      <button
                        id="maptoggle"
                        type="button"
                        class="btn btn-primary mr-1 filtersimple"
                      >
                        Uppdatera automatiskt
                        <i class="fa fa-refresh fa-spin mapstarted"></i
                        ><i class="fa fa-refresh mapstopped"></i>
                      </button>
                      <button
                        id="mapload"
                        type="button"
                        class="btn btn-primary mr-1 filteradvanced"
                        style="display: none"
                      >
                        Ladda
                      </button>
                    </div>
                  </div>
                  <div class="col">
                    <div
                      class="form-group filteradvanced"
                      style="display: none"
                    >
                      <label>Status</label>
                      <select
                        id="filterstatus"
                        class="form-control"
                        size="1"
                        multiple
                      >
                        <option
                          :value="workOrderState.Registered"
                          data-subtext="Registrerad"
                        >
                          Ej Påbörjade
                        </option>
                        <option
                          :value="`${workOrderState.InProgress},${workOrderState.WaitingForVerification},${workOrderState.WorkDone}`"
                          data-subtext="Startad & Arbete utfört"
                        >
                          Pågående
                        </option>
                        <option
                          :value="`${workOrderState.ReadyForInvoicing},${workOrderState.Invoiced},${workOrderState.Finished},${workOrderState.Cancelled}`"
                          data-subtext="Under beredning, Rapporterad & Avslutad"
                        >
                          Avslutade
                        </option>
                        <option data-divider="true"></option>
                        <option
                          data-icon="fa fa-tag state-registered"
                          :value="workOrderState.Registered"
                        >
                          {{ translateState(workOrderState.Registered) }}
                        </option>
                        <option
                          data-icon="fa fa-tag state-in-progress"
                          :value="workOrderState.InProgress"
                        >
                          {{ translateState(workOrderState.InProgress) }}
                        </option>
                        <option
                          data-icon="fa fa-tag state-waiting-for-verification"
                          :value="workOrderState.WaitingForVerification"
                        >
                          {{
                            translateState(
                              workOrderState.WaitingForVerification,
                            )
                          }}
                        </option>
                        <option data-divider="true"></option>
                        <option
                          data-icon="fa fa-tag state-work-done"
                          :value="workOrderState.WorkDone"
                        >
                          {{ translateState(workOrderState.WorkDone) }}
                        </option>
                        <option
                          data-icon="fa fa-tag state-ready-for-invoicing"
                          :value="workOrderState.ReadyForInvoicing"
                        >
                          {{ translateState(workOrderState.ReadyForInvoicing) }}
                        </option>
                        <option
                          data-icon="fa fa-tag state-invoiced"
                          :value="workOrderState.Invoiced"
                        >
                          {{ translateState(workOrderState.Invoiced) }}
                        </option>
                        <option
                          data-icon="fa fa-tag state-finished"
                          :value="workOrderState.Finished"
                        >
                          {{ translateState(workOrderState.Finished) }}
                        </option>
                        <option
                          data-icon="fa fa-tag state-cancelled"
                          :value="workOrderState.Cancelled"
                        >
                          {{ translateState(workOrderState.Cancelled) }}
                        </option>
                      </select>
                    </div>
                    <div
                      class="form-group filteradvanced"
                      style="display: none"
                    >
                      <label>Arbetstyp</label>
                      <select
                        id="filterworktypeid"
                        class="form-control"
                        size="1"
                        multiple
                      ></select>
                    </div>
                    <div class="form-row filteradvanced" style="display: none">
                      <div class="form-group col-md-6">
                        <label>Från och med</label>
                        <input
                          id="filterdatefrom"
                          type="text"
                          class="form-control"
                        />
                      </div>
                      <div class="form-group col-md-6">
                        <label>Till och med</label>
                        <input
                          id="filterdateto"
                          type="text"
                          class="form-control"
                        />
                      </div>
                    </div>
                    <div class="form-group">
                      <label>Data</label>
                      <select id="filterdatatype" class="form-control">
                        <option
                          value="0"
                          data-subtext="Före 09.00 - Planerade. Sedan nya AO, oavsett filter."
                        >
                          Automatisk
                        </option>
                        <option value="1" data-subtext="Filter enl ovan">
                          Nya arbetsordrar idag
                        </option>
                        <option value="2" data-subtext="Filter enl ovan">
                          Planerade arbetsordrar idag
                        </option>
                        <option value="5" data-subtext="Filter enl ovan">
                          Planerade arbetsordrar idag (grupperad)
                        </option>
                        <option
                          value="3"
                          disabled="disabled"
                          data-subtext="Filter enl ovan"
                        >
                          Alla arbetsordrar
                        </option>
                        <option
                          value="4"
                          disabled="disabled"
                          data-subtext="Filter enl ovan"
                        >
                          Planerade arbetsordrar
                        </option>
                      </select>
                    </div>
                    <div class="form-group">
                      <label>Visningstyp</label>
                      <select id="displaytype" class="form-control">
                        <option value="2">Fönster</option>
                        <option value="1">Fullskärm</option>
                      </select>
                    </div>
                  </div>
                </div>
              </form>
              <div
                v-if="['map'].indexOf($route.name) > -1"
                class="dropdown-divider"
              ></div>
              <a
                v-if="['map'].indexOf($route.name) > -1"
                id="filtermode"
                class="dropdown-item"
                data-toggletext="<span class='fa fa-retweet'></span>Avancerat läge. Byt till förenklat läge"
                ><span class="fa fa-retweet"></span>Förenklat läge. Byt till
                avancerat läge</a
              >
              <router-link to="signout" class="dropdown-item"
                ><span class="fa fa-sign-out"></span>Logga ut</router-link
              >
            </div>
          </li>
        </ul>
      </div>

      <div id="mobilebuttons">
        <div
          v-if="['mobile'].indexOf($route.name) > -1"
          class="btn btn-primary btntext"
          @click="openDesktopView()"
        >
          Desktopvy <span class="fa fa-external-link"></span>
        </div>
        <div
          v-if="['mobile-select'].indexOf($route.name) > -1"
          class="btn btn-primary btntext"
          @click="$router.go(-1)"
        >
          <span class="fa fa-arrow-left"></span> Tillbaka
        </div>
      </div>
    </nav>
    <main role="main">
      <router-view />
    </main>
  </div>
</template>

<script>
/* eslint-disable */
import store from "@/services/store";
import ajax from "@/services/ajax";
import util from "@/services/util";
import { workOrderState, translateState } from "@/core/work-order/status";

export default {
  data() {
    return {
      workOrderState,
      carEditorInit: false,
      signatureAwayInit: false,
    };
  },
  computed: {
    userData: function () {
      return store.state.userData;
    },
  },
  methods: {
    translateState,
    openCarEditor() {
      var _view = this;

      if (!this.carEditorInit) {
        // bind button events
        this.carEditorInit = true;
        if (util.userCanEdit()) {
          $("#careditoradd").on("click", function () {
            $("#careditor .modal-body tbody").append(
              '<tr class="bsedit"><td><input class="form-control" placeholder="Företag" maxlength="10"></td><td><input class="form-control" placeholder="Avdelning" maxlength="10"></td><td><input class="form-control" placeholder="Regnr" maxlength="10"></td><td class="text-right align-middle"><button type="button" class="btn btn-sm btn-success"><span class="fa fa-check"></span></button> <button type="button" class="btn btn-sm btn-danger"><span class="fa fa-remove"></span></button></td></tr>',
            );
            $("#careditor .modal-body").animate(
              {
                scrollTop: $("#careditor .modal-body tbody tr").last().offset()
                  .top,
              },
              500,
            );
            $("#careditor .modal-body tr:last td:first input").focus();
          });
          $("#careditor .modal-body").on("click", ".btn-primary", function () {
            let tr = $(this).closest("tr");
            tr.addClass("bsedit");
            let td = tr.children("td");
            td.eq(0).html(
              $(
                '<input class="form-control" placeholder="Företag" maxlength="10">',
              ).val(td.eq(0).text()),
            );
            td.eq(1).html(
              $(
                '<input class="form-control" placeholder="Avdelning" maxlength="10">',
              ).val(td.eq(1).text()),
            );
            td.eq(2).html(
              $(
                '<input class="form-control" placeholder="Regnr" maxlength="10">',
              ).val(td.eq(2).text()),
            );
            td.eq(3).html(
              '<button type="button" class="btn btn-sm btn-success"><span class="fa fa-check"></span></button> <button type="button" class="btn btn-sm btn-danger"><span class="fa fa-remove"></span></button></td>',
            );
          });
          $("#careditor .modal-body").on("click", ".btn-success", function () {
            let tr = $(this).closest("tr");
            let td = tr.children("td");
            let carid = tr.get(0).dataset.carid || "";
            let companyid = td.eq(0).children("input").val();
            let orgcode = td.eq(1).children("input").val();
            let regno = td.eq(2).children("input").val();
            ajax(
              {
                method: "post",
                url: "{ezip-api}/car/carsave",
                data: {
                  carid: carid.length == 0 ? null : carid,
                  companyid,
                  orgcode,
                  regno,
                },
              },
              _view.userData.user,
            )
              .then(function (response) {
                let i = response.data;
                tr.get(0).dataset.carid = i.carid;
                tr.removeClass("bsedit");
                tr.html(
                  "<td>" +
                    util.htmlEncode(i.companyid) +
                    "</td><td>" +
                    util.htmlEncode(i.orgcode) +
                    "</td><td>" +
                    util.htmlEncode(i.title) +
                    '</td><td class="text-right align-middle"><button type="button" class="btn btn-sm btn-primary"><span class="fa fa-edit"></span></button> <button type="button" class="btn btn-sm btn-danger"><span class="fa fa-remove"></span></button> <button type="button" class="btn btn-sm btn-success" style="display: none"><span class="fa fa-check"></span></button></td>',
                );
              })
              .catch(function (error) {
                console.log("Kunde inte spara rad!");
              });
          });
          $("#careditor .modal-body").on("click", ".btn-danger", function () {
            if (!confirm("Vill du göra verkligen radera den här bilen?"))
              return;
            let tr = $(this).closest("tr");
            let td = tr.children("td");
            let carid = tr.get(0).dataset.carid || "";
            if (carid.length == 0) {
              // just remove, this is an unsaved row
              tr.remove();
            } else {
              ajax(
                {
                  method: "get",
                  url: "{ezip-api}/car/carremove?carid=" + carid,
                },
                _view.userData.user,
              )
                .then(function (response) {
                  tr.remove();
                })
                .catch(function (error) {
                  console.log("Kunde inte radera rad!");
                });
            }
          });
        } else {
          // user can't edit
          $("#careditoradd")
            .attr("disabled", "disabled")
            .removeClass("btn-success")
            .addClass("btn-secondary");
        }
      }

      // show loader and load cars list
      $("#careditor .modal-body").html(
        '<div class="d-flex align-items-center justify-content-center" style="height: 350px" v-if="loaderVisible"><div class="spinner-border text-primary" role="status"><span class="sr-only">Laddar</span></div></div>',
      );
      ajax(
        {
          method: "get",
          url: "{ezip-api}/car/carslist?sortOrder=1",
        },
        _view.userData.user,
      ).then(function (response) {
        var h = [];
        response.data.data.forEach(function (i) {
          h.push(
            '<tr data-carid="' +
              i.carid +
              '"><td>' +
              util.htmlEncode(i.companyid) +
              "</td><td>" +
              util.htmlEncode(i.orgcode) +
              "</td><td>" +
              util.htmlEncode(i.title) +
              '</td><td class="text-right align-middle"><button type="button" class="btn btn-sm ' +
              (util.userCanEdit() ? "btn-primary" : "btn-secondary") +
              '"' +
              (util.userCanEdit() ? "" : ' disabled="disabled"') +
              '><span class="fa fa-edit"></span></button> <button type="button" class="btn btn-sm ' +
              (util.userCanEdit() ? "btn-danger" : "btn-secondary") +
              '"' +
              (util.userCanEdit() ? "" : ' disabled="disabled"') +
              '><span class="fa fa-remove"></span></button> <button type="button" class="btn btn-sm ' +
              (util.userCanEdit() ? "btn-success" : "btn-secondary") +
              '" style="display: none"' +
              (util.userCanEdit() ? "" : ' disabled="disabled"') +
              '><span class="fa fa-check"></span></button></td></tr>',
          );
        });
        $("#careditor .modal-body").html(
          `
						<table class="bstable">
						<thead>
							<tr>
							<th scope="col" style="width: 100px">Företag</th>
							<th scope="col" style="width: 100px">Avdelning</th>
							<th scope="col">Regnr</th>
							<th scope="col" style="width: 100px"></th>
							</tr>
						</thead>
						<tbody>
						` +
            h.join("") +
            `
						</tbody>
						</table>
					`,
        );
      });

      // show modal
      $("#careditor").modal("toggle");
    },

    openSignatureAwayEditor() {
      var _view = this;
      var _awayreasonselect = "",
        _awayreasonlookup = {};

      if (!this.signatureAwayInit) {
        // bind button events
        this.signatureAwayInit = true;

        $("#signatureawaytarget")
          .selectpicker({ noneSelectedText: "-", size: 15, liveSearch: true })
          .on("changed.bs.select", function () {
            let signature = util.getNiceString([
              $("#signatureawaytarget").val(),
            ]);

            $("#signatureawayeditor .modal-body").html(
              '<div class="d-flex align-items-center justify-content-center" style="height: 350px" v-if="loaderVisible"><div class="spinner-border text-primary" role="status"><span class="sr-only">Laddar</span></div></div>',
            );
            ajax(
              {
                method: "get",
                url: "{ezip-api}/away/awayslist?signature=" + signature,
              },
              _view.userData.user,
            ).then(function (response) {
              var h = [];
              response.data.forEach(function (i) {
                h.push(
                  '<tr data-awayid="' +
                    util.htmlEncode(i.awayid) +
                    '"><td>' +
                    util.htmlEncode(i.datefrom) +
                    "</td><td>" +
                    util.htmlEncode(i.dateto) +
                    '</td><td data-value="' +
                    util.htmlEncode(i.awayreasonid) +
                    '">' +
                    util.htmlEncode(_awayreasonlookup[i.awayreasonid].title) +
                    '</td><td data-value="' +
                    util.htmlEncode(i.comment) +
                    '">' +
                    util.htmlEncode(i.comment) +
                    '</td><td class="text-right align-middle"><button type="button" class="btn btn-sm ' +
                    (util.userCanEdit() ? "btn-primary" : "btn-secondary") +
                    '"' +
                    (util.userCanEdit() ? "" : ' disabled="disabled"') +
                    '><span class="fa fa-edit"></span></button> <button type="button" class="btn btn-sm ' +
                    (util.userCanEdit() ? "btn-danger" : "btn-secondary") +
                    '"' +
                    (util.userCanEdit() ? "" : ' disabled="disabled"') +
                    '><span class="fa fa-remove"></span></button> <button type="button" class="btn btn-sm ' +
                    (util.userCanEdit() ? "btn-success" : "btn-secondary") +
                    '" style="display: none"' +
                    (util.userCanEdit() ? "" : ' disabled="disabled"') +
                    '><span class="fa fa-check"></span></button></td></tr>',
                );
              });
              $("#signatureawayeditor .modal-body").html(
                `
									<table class="bstable">
									<thead>
										<tr>
										<th scope="col" style="width: 100px" title="Från och med (hela dagen) - Ange i kommentaren ifall det endast gäller vissa tider, etc.">F.o.m.</th>
										<th scope="col" style="width: 100px" title="Till och med (hela dagen) - Ange i kommentaren ifall det endast gäller vissa tider, etc.">T.o.m.</th>
										<th scope="col" style="width: 100px">Anledning</th>
										<th scope="col">Kommentar</th>
										<th scope="col" style="width: 100px"></th>
										</tr>
									</thead>
									<tbody>
									` +
                  h.join("") +
                  `
									</tbody>
									</table>
								`,
              );
            });
          });

        if (util.userCanEdit()) {
          $("#signatureawayadd").on("click", function () {
            let signature = util.getNiceString([
              $("#signatureawaytarget").val(),
            ]);
            if (signature.length == 0) return;

            let tr = $(
              '<tr class="bsedit"><td><input class="form-control" placeholder="Från och med" maxlength="10"></td><td><input class="form-control" placeholder="Till och med" maxlength="10"></td><td><select class="form-control" >' +
                _awayreasonselect +
                '</select></td><td><input class="form-control" placeholder="Kommentar" maxlength=255></td><td class="text-right align-middle"><button type="button" class="btn btn-sm btn-success"><span class="fa fa-check"></span></button> <button type="button" class="btn btn-sm btn-danger"><span class="fa fa-remove"></span></button></td></tr>',
            );
            $("#signatureawayeditor .modal-body tbody").append(tr);
            $("#signatureawayeditor .modal-body").animate(
              { scrollTop: tr.offset().top },
              500,
            );
            $(
              "#signatureawayeditor .modal-body tr:last td:first input",
            ).focus();

            let f = function (i, role, buddy) {
              i.pikaday = new Pikaday({
                field: i,
                format: "YYYY-MM-DD",
                firstDay: 1,
                disableWeekends: false,
                showWeekNumber: true,
                i18n: {
                  previousMonth: "Föregående månad",
                  nextMonth: "Nästa månad",
                  months: [
                    "Januari",
                    "Februari",
                    "Mars",
                    "April",
                    "Maj",
                    "Juni",
                    "Juli",
                    "Augusti",
                    "September",
                    "Oktober",
                    "November",
                    "December",
                  ],
                  weekdays: [
                    "Söndag",
                    "Måndag",
                    "Tisdag",
                    "Onsdag",
                    "Torsdag",
                    "Fredag",
                    "Lördag",
                  ],
                  weekdaysShort: [
                    "Sön",
                    "Mån",
                    "Tis",
                    "Ons",
                    "Tor",
                    "Fre",
                    "Lör",
                  ],
                },
                onSelect: function (dateString) {
                  let d = new Date(dateString);
                  i.value = util.formatDate(d);
                  if (role == "from") {
                    buddy.pikaday.setMinDate(d);
                    buddy.pikaday.setMaxDate(null);
                  } else {
                    buddy.pikaday.setMaxDate(d);
                    buddy.pikaday.setMinDate(null);
                  }
                },
              });
            };
            f(
              tr.find("input").eq(0).get(0),
              "from",
              tr.find("input").eq(1).get(0),
            );
            f(
              tr.find("input").eq(1).get(0),
              "to",
              tr.find("input").eq(0).get(0),
            );
          });

          $("#signatureawayeditor .modal-body").on(
            "click",
            ".btn-primary",
            function () {
              let tr = $(this).closest("tr");
              tr.addClass("bsedit");
              let td = tr.children("td");
              td.eq(0).html(
                $(
                  '<input class="form-control" placeholder="Från och med" maxlength="10">',
                ).val(td.eq(0).text()),
              );
              td.eq(1).html(
                $(
                  '<input class="form-control" placeholder="Till och med" maxlength="10">',
                ).val(td.eq(1).text()),
              );
              td.eq(2).html(
                $(
                  '<select class="form-control" >' +
                    _awayreasonselect +
                    "</select>",
                ).val(td.eq(2).get(0).dataset.value),
              );
              td.eq(3).html(
                $(
                  '<input class="form-control" placeholder="Kommentar" maxlength=255>',
                ).val(td.eq(3).get(0).dataset.value),
              );
              td.eq(4).html(
                '<button type="button" class="btn btn-sm btn-success"><span class="fa fa-check"></span></button> <button type="button" class="btn btn-sm btn-danger"><span class="fa fa-remove"></span></button></td>',
              );

              let f = function (i, role, buddy) {
                i.pikaday = new Pikaday({
                  field: i,
                  format: "YYYY-MM-DD",
                  firstDay: 1,
                  disableWeekends: false,
                  showWeekNumber: true,
                  i18n: {
                    previousMonth: "Föregående månad",
                    nextMonth: "Nästa månad",
                    months: [
                      "Januari",
                      "Februari",
                      "Mars",
                      "April",
                      "Maj",
                      "Juni",
                      "Juli",
                      "Augusti",
                      "September",
                      "Oktober",
                      "November",
                      "December",
                    ],
                    weekdays: [
                      "Söndag",
                      "Måndag",
                      "Tisdag",
                      "Onsdag",
                      "Torsdag",
                      "Fredag",
                      "Lördag",
                    ],
                    weekdaysShort: [
                      "Sön",
                      "Mån",
                      "Tis",
                      "Ons",
                      "Tor",
                      "Fre",
                      "Lör",
                    ],
                  },
                  onSelect: function (dateString) {
                    let d = new Date(dateString);
                    i.value = util.formatDate(d);
                    if (role == "from") {
                      buddy.pikaday.setMinDate(d);
                      buddy.pikaday.setMaxDate(null);
                    } else {
                      buddy.pikaday.setMaxDate(d);
                      buddy.pikaday.setMinDate(null);
                    }
                  },
                });
              };
              let a = tr.find("input").eq(0).get(0);
              let b = tr.find("input").eq(1).get(0);
              f(a, "from", b);
              f(b, "to", a);
              b.pikaday.setMinDate(new Date(a.value));
              b.pikaday.setMaxDate(null);
              a.pikaday.setMaxDate(new Date(b.value));
              a.pikaday.setMinDate(null);
            },
          );

          $("#signatureawayeditor .modal-body").on(
            "click",
            ".btn-success",
            function () {
              let tr = $(this).closest("tr");
              let td = tr.children("td");
              let awayid = tr.get(0).dataset.awayid || "";
              let datefrom = td.eq(0).children("input").val();
              let dateto = td.eq(1).children("input").val();
              let awayreasonid = td.eq(2).children("select").val();
              let comment = td.eq(3).children("input").val();
              let signature = util.getNiceString([
                $("#signatureawaytarget").val(),
              ]);
              ajax(
                {
                  method: "post",
                  url: "{ezip-api}/away/awaysave",
                  data: {
                    awayid: awayid.length == 0 ? null : awayid,
                    signature,
                    datefrom,
                    dateto,
                    awayreasonid,
                    comment,
                  },
                },
                _view.userData.user,
              )
                .then(function (response) {
                  let i = response.data;
                  tr.get(0).dataset.awayid = i.awayid;
                  tr.removeClass("bsedit");
                  tr.html(
                    "<td>" +
                      util.htmlEncode(i.datefrom) +
                      "</td><td>" +
                      util.htmlEncode(i.dateto) +
                      '</td><td data-value="' +
                      util.htmlEncode(i.awayreasonid) +
                      '">' +
                      util.htmlEncode(_awayreasonlookup[i.awayreasonid].title) +
                      '</td><td data-value="' +
                      util.htmlEncode(i.comment) +
                      '">' +
                      util.htmlEncode(i.comment) +
                      '</td><td class="text-right align-middle"><button type="button" class="btn btn-sm btn-primary"><span class="fa fa-edit"></span></button> <button type="button" class="btn btn-sm btn-danger"><span class="fa fa-remove"></span></button> <button type="button" class="btn btn-sm btn-success" style="display: none"><span class="fa fa-check"></span></button></td>',
                  );
                })
                .catch(function (error) {
                  console.log("Kunde inte spara rad!");
                });
            },
          );

          $("#signatureawayeditor .modal-body").on(
            "click",
            ".btn-danger",
            function () {
              if (!confirm("Vill du göra verkligen radera den här raden?"))
                return;
              let tr = $(this).closest("tr");
              let td = tr.children("td");
              let awayid = tr.get(0).dataset.awayid || "";
              if (awayid.length == 0) {
                // just remove, this is an unsaved row
                tr.remove();
              } else {
                ajax(
                  {
                    method: "get",
                    url: "{ezip-api}/away/awayremove?awayid=" + awayid,
                  },
                  _view.userData.user,
                )
                  .then(function (response) {
                    tr.remove();
                  })
                  .catch(function (error) {
                    console.log("Kunde inte radera rad!");
                  });
              }
            },
          );
        } else {
          // user can't edit
          $("#signatureawayadd")
            .attr("disabled", "disabled")
            .removeClass("btn-success")
            .addClass("btn-secondary");
        }
      }

      // show loader and load all signatures
      $("#signatureawaytarget").html("").selectpicker("refresh");
      $("#signatureawayeditor .modal-body").html(
        '<div class="d-flex align-items-center justify-content-center" style="height: 350px" v-if="loaderVisible"><div class="spinner-border text-primary" role="status"><span class="sr-only">Laddar</span></div></div>',
      );

      // load signatures
      ajax(
        {
          method: "get",
          url: "{ezip-api}/signature/signatureslist",
        },
        _view.userData.user,
      ).then(function (response) {
        var h = [];
        h.push("<option></option>");
        response.data.data.forEach(function (item) {
          h.push(
            '<option value="' +
              util.htmlEncode(item.signature) +
              '" data-companyid="' +
              item.companyid +
              '" data-orgcode="' +
              item.orgcode +
              '" data-icon="fa fa-user">' +
              util.htmlEncode(item.title) +
              "</option>",
          );
        });
        $("#signatureawaytarget").html(h.join("")).selectpicker("refresh");
        $("#signatureawayeditor .modal-body").html(
          '<div class="d-flex align-items-center justify-content-center" style="height: 350px">Välj person för frånvarohantering i listan ovan.</div>',
        );
      });

      // load awayreasons
      ajax(
        {
          method: "get",
          url: "{ezip-api}/away/awayreasonlist",
        },
        _view.userData.user,
      ).then(function (response) {
        let h = [],
          l = {};
        response.data.forEach(function (item) {
          h.push(
            '<option value="' +
              util.htmlEncode(item.awayreasonid) +
              '" style="color: #' +
              item.color +
              '">' +
              util.htmlEncode(item.title) +
              "</option>",
          );
          l[item.awayreasonid] = item;
        });
        _awayreasonselect = h.join("");
        _awayreasonlookup = l;
      });

      // show modal
      $("#signatureawayeditor").modal("toggle");
    },

    openMobileView() {
      window.open("/mobile");
    },

    openDesktopView() {
      window.open("/start");
    },
  },
};
</script>

<style>
.navbar-form {
  width: 600px;
}
.navbar {
  justify-content: flex-start;
}
.navbar-brand {
  padding-left: 10px;
  padding-top: 16px;
}
.mobile #navbar,
#navbar.hide {
  display: none !important;
}
.mobile .btntext > .fa {
  margin-left: 4px;
}
#mobilebuttons {
  justify-content: flex-end !important;
  flex-basis: auto;
  display: flex;
  flex-grow: 1;
}
#mobilebuttons:empty {
  display: none;
}

.dropdown-item > .fa {
  width: 20px;
}

#signatureawayeditor .dropdown-menu.show {
  width: 300px !important;
}
#signatureawayeditor .dropdown-menu.show .inner.show {
  overflow-x: hidden;
}
#careditor .modal-body,
#signatureawayeditor .modal-body {
  min-height: 400px;
}
.bstable {
  width: 100%;
}
.bstable tbody:before {
  content: "@";
  display: block;
  line-height: 4px;
  text-indent: -99999px;
}
.bstable th {
  padding: 0.75rem;
  vertical-align: top;
  border-top: 1px solid #dee2e6;
}
.bstable td {
  padding: 0.15rem;
  vertical-align: top;
}
.bstable td {
  padding: 0 11px 0 11px;
  line-height: 240%;
}
.bstable td:last-child {
  padding: 0 !important;
}
.bstable .bsedit td {
  padding: 0 11px 0 0 !important;
}
.bstable .bsedit td:last-child {
  padding: 0 !important;
}
.bstable thead th {
  vertical-align: bottom;
  border-bottom: 2px solid #dee2e6;
}

.state-registered {
  color: #8e24aa;
}

.state-in-progress {
  color: #1e88e5;
}

.state-waiting-for-verification {
  color: #fb8c00;
}

.state-work-done {
  color: #43a047;
}

.state-ready-for-invoicing {
  color: #3949ab;
}

.state-cancelled {
  color: #e53935;
}

.state-invoiced {
  color: #1e88e5;
}

.state-finished {
  color: #00897b;
}

.state-deleted {
  color: #757575;
}

.state-misc {
  color: #757575;
}
</style>
