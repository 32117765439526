<template>
  <div class="row">
    <div class="col">
      <h1>Du loggas nu ut</h1>
    </div>
  </div>
</template>

<script>
import store from "@/services/store";

export default {
  async created() {
    // sign out
    await store.dispatch("userLogout");
  },
};
</script>
