// routes
import { createRouter, createWebHistory } from "vue-router";
import store from "@/services/store";

import Default from "@/views/Default.vue";
import NoPlanner from "@/views/NoPlanner.vue";

import Signin from "@/views/auth/Signin.vue";
import Callback from "@/views/auth/Callback.vue";
import Unauthorized from "@/views/auth/Unauthorized.vue";
import Signout from "@/views/auth/Signout.vue";
import util from "@/services/util";

const router = createRouter({
  history: createWebHistory(),
  routes: [
    { path: "/", name: "default", component: Default },
    {
      name: "planning-create",
      path: "/planning/create",
      meta: { skipUserInit: true },
      redirect: (to) => {
        return util.isMobile()
          ? {
              name: "mobile-select",
              query: {
                type: "workorder",
                date: new Date().toLocaleDateString("sv-SE", {
                  dateStyle: "short",
                }),
                workOrderNumber: to.query.workOrderNumber,
                utm_source: to.query.utm_source,
              },
            }
          : {
              name: "start",
              query: {
                workOrderNumber: to.query.workOrderNumber,
                utm_source: to.query.utm_source,
              },
            };
      },
    },
    {
      path: "/noplanner",
      name: "noplanner",
      meta: { skipUserInit: true },
      component: NoPlanner,
    },
    {
      path: "/signin",
      name: "signin",
      meta: { skipUserInit: true },
      component: Signin,
    },
    {
      path: "/callback",
      name: "callback",
      meta: { skipUserInit: true },
      component: Callback,
    },
    {
      path: "/unauthorized",
      name: "Unauthorized",
      meta: { skipUserInit: true },
      component: Unauthorized,
    },
    {
      path: "/signout",
      name: "signout",
      meta: { skipUserInit: true },
      component: Signout,
    },
    {
      path: "/start",
      name: "start",
      meta: { requiresAuth: true },
      props: (route) => ({ workOrderNumber: route.query.workOrderNumber }),
      component: () => import("@/views/Start.vue"),
    },
    {
      path: "/map",
      name: "map",
      meta: { requiresAuth: true },
      component: () => import("@/views/Map.vue"),
    },
    {
      path: "/mobile",
      name: "mobile",
      meta: { requiresAuth: true },
      component: () => import("@/views/Mobile.vue"),
    },
    {
      path: "/mobile-select",
      name: "mobile-select",
      meta: { requiresAuth: true },
      props: (route) => ({ workOrderNumber: route.query.workOrderNumber }),
      component: () => import("@/views/MobileSelect.vue"),
    },
    {
      path: "/mobile-edit",
      name: "mobile-edit",
      meta: { requiresAuth: true },
      component: () => import("@/views/MobileEdit.vue"),
    },
  ],
});

router.beforeEach(async (to, from, next) => {
  // ensure that store.state.userData is initialized with any current user and roles
  if (
    to.matched.some((record) => record.meta.requiresAuth) ||
    to.matched.some((record) => !record.meta.skipUserInit)
  ) {
    // we need this if requiresAuth == true but may otherwise skip it if skipUserInit == true
    console.log(
      "router.beforeEach(): start. path is " +
        to.path +
        ", calling store.userInit()",
    );
    await store.dispatch("userInit");
  } else {
    console.log(
      "router.beforeEach(): start. path is " +
        to.path +
        ", skipped store.userInit()",
    );
  }

  if (to.matched.some((record) => record.meta.requiresAuth)) {
    if (store.state.userData.isAuthenticated) {
      console.log(
        "router.beforeEach(): path is " + to.path + ", user is authenticated.",
      );
      next();
      return;
    } else {
      console.log(
        "router.beforeEach(): path is " +
          to.path +
          ", user is not authenticated. calling store.userLogin()",
      );
      await store.dispatch("userLogin", to.fullPath);
      next(false);
      return;
    }
  } else {
    console.log(
      "router.beforeEach(): path is " +
        to.path +
        ", authentication not required.",
    );
    next();
  }
});

export default router;
