// utility functions
/* eslint-disable */
import store from "@/services/store";

export default {
  workTypeIds: [
    { worktypeid: "FU-FU", title: "Fuktkontroll" },
    { worktypeid: "FU-AV", title: "Fukt-Avfuktning" },
    { worktypeid: "FU-BE", title: "Fuktbesiktning" },
    { worktypeid: "SA-SA", title: "Sanering" },
    { worktypeid: "SA-LU", title: "Luktsanering" },
    { worktypeid: "SA-BE", title: "Sanering Besiktning" },
    { worktypeid: "SA-KL", title: "Klottersanering" },
    { worktypeid: "SA-SP", title: "Specialsanering" },
    { worktypeid: "FB-BE", title: "Fog och brand Besiktning" },
    { worktypeid: "FB-BT", title: "Brandtätning" },
    { worktypeid: "FB-SA", title: "Fog och brand Sanering" },
    { worktypeid: "AS-SA", title: "Asbestsanering" },
    { worktypeid: "AS-BE", title: "Asbest Besiktning" },
    { worktypeid: "BM-BE", title: "Byggmiljö Besiktning" },
    { worktypeid: "BM-RBK", title: "RBK" },
    { worktypeid: "BM-PL", title: "Projektledning" },
    { worktypeid: "VE-BE", title: "Ventilation Besiktning" },
    { worktypeid: "VE-MO", title: "Montering" },
    { worktypeid: "VE-SA", title: "Ventioation Sanering" },
    { worktypeid: "RI-BE", title: "Rivning-Besiktning" },
    { worktypeid: "RI-RI", title: "Rivning" },
    { worktypeid: "RK-BE", title: "Radon Besiktning" },
    { worktypeid: "RK-IN", title: "Radon Installation" },
    { worktypeid: "RK-MÄ", title: "Radon Mätning" },
    { worktypeid: "RK-SE", title: "Radon Serviceavtal" },
    { worktypeid: "JO-DJ", title: "DagJour" },
    { worktypeid: "JO-JO", title: "Jour" },
    { worktypeid: "JO-FA", title: "Fastighetsjour" },
    { worktypeid: "SK", title: "Storskada" },
    { worktypeid: "AS-PCB", title: "PCB Sanering" },
    { worktypeid: "NHS-IS", title: "Industriservice" },
    { worktypeid: "NHS-KBS", title: "NHS Kombibil Stor" },
    { worktypeid: "NHS-CB", title: "Containerbil" },
    { worktypeid: "FU-MG", title: "Fukt/mögelutredning" },
    { worktypeid: "SA-LS", title: "Lösöreshantering" },
    { worktypeid: "SA-FA", title: "Sanering Fastighet" },
    { worktypeid: "SA-SLS", title: "Sanering Lösöre" },
    { worktypeid: "MCM-F", title: "Filmning" },
    { worktypeid: "MCR-RE", title: "Rensning" },
    { worktypeid: "MCM-R", title: "Relining" },
    { worktypeid: "SA-ST", title: "Städning" },
    { worktypeid: "ÖV-ÖVR", title: "Övrigt" },
    { worktypeid: "SAN-HT", title: "Högtryckstvättning" },
    { worktypeid: "SAN-UV", title: "Ultrarent vatten" },
    { worktypeid: "SAN-BL", title: "Blästring" },
    { worktypeid: "MÅ-MÅ", title: "Målning" },
    { worktypeid: "NHS-SB", title: "NHS Spolbil" },
    { worktypeid: "NHS-KB", title: "NHS Kombibil" },
    { worktypeid: "NHS-RS", title: "NHS Rotskärning" },
    { worktypeid: "NHS-FR", title: "NHS Fräsning" },
    { worktypeid: "NHS-KS", title: "NHS Klottersanering" },
    { worktypeid: "NHS-JO", title: "NHS Jour" },
    { worktypeid: "NHS-TV", title: "NHS TV Inspektion" },
  ],
  workTypeImage: {
    "AS-BE": "as-be",
    ASBEST: "asbest",
    "AS-PCB": "as-pcb",
    "AS-SA": "as-sa",
    "BM-BE": "bm-be",
    "BM-PL": "bm-pl",
    "BM-RBK": "bm-rbk",
    BRAND: "brand",
    BYGGNADSMILJÖ: "byggnadsmilj%C3%B6",
    FASTIGHETSJOUR: "fastighetsjour",
    FASTIGHETSSERVICE: "fastighetsservice",
    "FB-BE": "fb-be",
    "FB-BT": "fb-bt",
    "FB-FO": "fb-fo",
    "FB-SA": "fb-sa",
    "FU-AV": "fu-av",
    "FU-BE": "fu-be",
    "FU-FU": "fu-fu",
    FUKT: "fukt",
    "FU-MG": "fu-mg",
    INDUSTRISERVICE: "industriservice",
    "JO-DJ": "jo-dj",
    "JO-FA": "jo-fa",
    "JO-JO": "jo-jo",
    JOUR: "jour",
    KLOTTER: "klotter",
    "MCM-F": "mcm-f",
    "MCM-R": "mcm-r",
    "MCM-RE": "mcm-re",
    MÅLNING: "m%C3%A5lning",
    "MÅ-MÅ": "m%C3%A5-m%C3%A5",
    "NHS-CB": "nhs-cb",
    "NHS-FR": "nhs-fr",
    "NHS-IS": "nhs-is",
    "NHS-JO": "nhs-jo",
    "NHS-KB": "nhs-kb",
    "NHS-KBS": "nhs-kbs",
    "NHS-KS": "nhs-ks",
    "NHS-RS": "nhs-rs",
    "NHS-SB": "nhs-sb",
    "NHS-TV": "nhs-tv",
    PCB: "pcb",
    RADON: "radon",
    "RI-BE": "ri-be",
    "RI-RI": "ri-ri",
    RIVNING: "rivning",
    "RK-BE": "rk-be",
    "RK-IN": "rk-in",
    "RK-MÄ": "rk-m%C3%A4",
    "RK-SE": "rk-se",
    "SA-BE": "sa-be",
    "SA-FA": "sa-fa",
    "SA-KL": "sa-kl",
    "SA-LS": "sa-ls",
    "SA-LU": "sa-lu",
    "SAN-BL": "san-bl",
    "SAN-HT": "san-ht",
    "SANITÄR OLÄGENHET": "sanit%C3%A4r-ol%C3%A4genhet",
    "SAN-UV": "san-uv",
    "SA-SA": "sa-sa",
    "SA-SLS": "sa-sls",
    "SA-SP": "sa-sp",
    "SA-ST": "sa-st",
    SERVICEÅTAGANDE: "service%C3%A5tagande",
    SKADEDOKUMENTATION: "skadedokumentation",
    SMITTORISK: "smittorisk",
    TRANSPORT: "transport",
    "UNDERHÅLL BYGGNADER": "underh%C3%A5ll-byggnader",
    "VE-BE": "ve-be",
    "VE-MO": "ve-mo",
    VENTILATION: "ventilation",
    "VE-SA": "ve-sa",
    ÖVRIGT: "%C3%B6vrigt",
    "ÖV-ÖVR": "%C3%B6v-%C3%B6vr",
  },
  workTypeImageName(workTypeId) {
    if (!workTypeId || workTypeId === "") {
      return "none";
    }
    if (!this.workTypeImage.hasOwnProperty(workTypeId)) {
      return "none";
    }
    return this.workTypeImage[workTypeId];
  },
  workTypeIconUrl(workTypeId) {
    const imageName = this.workTypeImageName(workTypeId);

    return `/static/images/icons/${imageName}.png`;
  },

  // translates a worktypeid to text
  // note: also exists as a server-version
  translateWorkTypeId(worktypeid, legacyTitle) {
    worktypeid = (worktypeid || "").toUpperCase();
    for (let x = 0; x < this.workTypeIds.length; x++) {
      if (this.workTypeIds[x].worktypeid == worktypeid)
        return (
          this.toTitleCase(this.workTypeIds[x].title) + " (" + worktypeid + ")"
        );
    }
    return legacyTitle ? worktypeid : this.toTitleCase(worktypeid);
  },

  // returns an array from [null, [123, 456]] e.g. [] or ["123","456"] without nulls or empty strings
  getNiceArray: function (hive) {
    let result = (hive.find((e) => e != null) || []).filter(
      (e) => e != null && (e + "").length > 0,
    );
    return result;
  },

  getNiceString: function (hive) {
    let result = (hive.find((e) => e != null) || "") + "";
    return result;
  },

  // escapes a regex
  escapeEx: function (value) {
    return value.replace(/[-\/\\^$*+?.()|[\]{}]/g, "\\$&");
  },

  htmlEncode: function (value) {
    return $("<div/>").text(value).html();
  },

  // returns a value from the querystring
  queryString: function (name) {
    let results = new RegExp("[?&]" + name + "=([^&#]*)").exec(
      window.location.search,
    );
    return results !== null ? results[1] || "" : "";
  },

  // creates an element and appends it to the parent, returns the element
  createElement: function (parent, tagName) {
    let e = document.createElement(tagName);
    for (let x = 2; x < arguments.length; x += 2) {
      e.setAttribute(arguments[x], arguments[x + 1]);
    }
    if (parent) parent.appendChild(e);
    return e;
  },

  // sets attributes of the element
  setAttribute: function (e) {
    for (let x = 1; x < arguments.length; x += 2) {
      e.setAttribute(arguments[x], arguments[x + 1]);
    }
    return e;
  },

  // loads the scripts in the array, one by one
  loadScripts: function (scripts) {
    var deferred = $.Deferred();

    function loadScript(i) {
      if (i < scripts.length) {
        $.ajax({
          url: scripts[i],
          dataType: "script",
          cache: true,
          success: function () {
            loadScript(i + 1);
          },
        });
      } else {
        deferred.resolve();
      }
    }
    loadScript(0);

    return deferred;
  },

  // https://gist.github.com/barryvan/2034786
  getCellX: function (target, rows) {
    let matrix = [];

    for (let i = 0; i < rows.length; i++) {
      matrix[i] = matrix[i] || [];
      let row = rows[i];
      let cells = row.cells;

      for (let j = 0; j < cells.length; j++) {
        let cell = cells[j];
        let colspan = cell.colSpan || 1;
        let rowspan = cell.rowSpan || 1;

        let rowIndex = row.rowIndex;
        matrix[rowIndex] = matrix[rowIndex] || [];

        let colIndex = null;
        for (
          let l = 0;
          l <= matrix[rowIndex].length && colIndex === null;
          l++
        ) {
          if (!matrix[rowIndex][l]) colIndex = l;
        }

        if (cell === target) {
          // Short circuit if possible.
          return colIndex;
        }

        for (let k = rowIndex; k < rowIndex + rowspan; k++) {
          for (let l = colIndex; l < colIndex + colspan; l++) {
            matrix[k] = matrix[k] || [];
            matrix[k][l] = 1;
          }
        }
      }
    }

    return null;
  },

  userCanView() {
    return (
      store.state.userData.isifsplanner == 1 &&
      store.state.userData.roles.indexOf("se.eaztimate.ifsplanering.user") > -1
    );
  },

  userCanEdit() {
    return (
      store.state.userData.isifsplanner == 1 &&
      store.state.userData.roles.indexOf("se.eaztimate.ifsplanering.planner") >
        -1
    );
  },

  trim(value, chars) {
    return value.replace(
      new RegExp(
        "^[\\s\\uFEFF\\xA0" + chars + "]+|[\\s\\uFEFF\\xA0" + chars + "]+$",
        "g",
      ),
      "",
    );
  },

  formatAddress(address) {
    // remove empty rows
    address = address.replace(/\$\.\$/gi, "$");

    // remove row break between zipcode and city - e.g. 12345$CITY
    address = address.replace(/(\$[0-9]{3,6})\$/gi, "$1 ");

    // remove any first/last markers
    address = this.trim(address, "$");

    // replace all $ with comma
    address = address.replace(/\$/g, ", ");

    return address;
  },

  formatDate(date) {
    const year = date.getFullYear(),
      month = date.getMonth() + 1,
      day = date.getDate(),
      formattedDate = [
        year,
        month < 10 ? "0" + month : month,
        day < 10 ? "0" + day : day,
      ].join("-");
    return formattedDate;
  },

  formatDateLong(date) {
    return date.toLocaleDateString("sv-SE", {
      weekday: "short",
      month: "numeric",
      day: "numeric",
    });
  },

  formatTime(timeString, timeStringEnd) {
    timeString = timeString || "";
    timeStringEnd = timeStringEnd || "";
    if (timeString.length == 4 && timeStringEnd.length == 4) {
      return this.formatTime(timeString) + "-" + this.formatTime(timeStringEnd);
    } else if (timeStringEnd.length == 4) {
      return "-" + this.formatTime(timeStringEnd);
    }
    if (timeString.length != 4) return "";
    return timeString.substring(0, 2) + ":" + timeString.substring(2);
  },

  isDate(dateString) {
    return (
      new Date(dateString) !== "Invalid Date" && !isNaN(new Date(dateString))
    );
  },

  tryParseDate(dateString, def) {
    return this.isDate(dateString) ? new Date(dateString) : def;
  },

  addDaysToDate(date, days) {
    let result = new Date(date);
    result.setDate(result.getDate() + days);
    return result;
  },

  isInt(value) {
    return (
      !isNaN(value) &&
      parseInt(Number(value)) == value &&
      !isNaN(parseInt(value, 10))
    );
  },

  tryParseInt(value, def) {
    return this.isInt(value) ? parseInt(value, 10) : def;
  },

  arrayMove(arr, fromIndex, toIndex) {
    let element = arr[fromIndex];
    arr.splice(fromIndex, 1);
    arr.splice(toIndex, 0, element);
  },

  in(val) {
    for (let x = 1; x < arguments.length; x++) {
      if ((val || "null").toString() == (arguments[x] || "null").toString())
        return true;
    }
    return false;
  },

  isMobile() {
    let result = window.matchMedia("(pointer: coarse)").matches;
    return result;
  },

  toTitleCase(str) {
    return str.replace(/[\wåäöÅÄÖ]\S*/g, function (txt) {
      return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
    });
  },

  buttonLoader(t, state) {
    let fa = t.find(".fa");
    if (state) {
      // setup loader
      t.attr("disabled", "disabled");
      t.data("data-buttonloader", {
        class: t.attr("class"),
        faclass: fa.attr("class"),
      });
      t.attr("class", "btn-secondary " + t.attr("class"));
      if (fa.hasClass("fa-refresh")) {
        fa.addClass("fa-spin");
      } else {
        fa.attr("class", "fa fa-circle-o-notch fa-spin");
      }
    } else {
      // remove loader
      t.removeAttr("disabled");
      t.attr("class", t.data("data-buttonloader").class);
      fa.attr("class", t.data("data-buttonloader").faclass);
    }
  },

  determineColors(bgcolor, el) {
    if ((bgcolor || "").length != 6) {
      return;
    }

    // get rgb
    let r = parseInt(bgcolor.substr(0, 2), 16);
    let g = parseInt(bgcolor.substr(2, 2), 16);
    let b = parseInt(bgcolor.substr(4, 2), 16);

    // calculates perceived lightness using the sRGB Luma method: Luma = (red * 0.2126 + green * 0.7152 + blue * 0.0722) / 255
    let pl = (r * 0.2126 + g * 0.7152 + b * 0.0722) / 255;
    let threshold = 0.5;
    let l = Math.trunc((pl - threshold) * -10000000);

    // calculates border alpha
    let borderthreshold = 0.8;
    let ba = (pl - borderthreshold) * 100;

    el.style.backgroundColor = "#" + bgcolor;
    el.style.color = "hsl(0,0%," + l + "%)";
    el.style.borderColor =
      "rgba(" + (r - 50) + "," + (g - 50) + "," + (b - 50) + "," + ba + ")";
  },
};
