<template>
  <div class="row">
    <div class="col">
      <h1>Tyvärr</h1>
      <p>
        Tyvärr verkar det inte som ditt konto har tillgång till IFS Planner,
        eller så saknar du lägsta behörigheten 'Användare'. Vänligen kontakta
        den som administrerar ditt Eaztimate IFS Planner konto för assistans.
      </p>

      <router-link to="signout" class="btn btn-primary" type="button"
        >Logga ut</router-link
      >
    </div>
  </div>
</template>
