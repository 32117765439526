// bridge/proxy for all ajax calls we do. used to inject the correct url and JWT token
import axios from "axios";
import { configValue } from "@/services/config";

export default (config, user) => {
  if (config.url.indexOf("{ezip-api}") < 0) return axios(config);

  config.url = config.url.replace(
    "{ezip-api}",
    configValue("VUE_APP_EZIP_API_EP"),
  );
  if (user && user.access_token) {
    config.headers = { Authorization: "Bearer " + user.access_token };
  }

  return axios(config);
};
