<template>
  <div class="row">
    <div class="col">
      <h1>Åtkomst nekas</h1>
      <p>Något gick fel vid inloggningen. Vänligen försök igen.</p>

      <router-link to="signin" class="btn btn-primary" type="button"
        >Logga in</router-link
      >
    </div>
  </div>
</template>
