<template>
  <div class="row">
    <div class="col">
      <h1>Du loggas nu in</h1>
    </div>
  </div>
</template>

<script>
import oidcmgr from "@/services/oidcmgr";
import store from "@/services/store";
import util from "@/services/util";

function defaultRootPage() {
  if (!util.userCanView()) {
    return { name: "noplanner" };
  } else if (util.isMobile()) {
    return { name: "mobile" };
  } else {
    return { name: "start" };
  }
}

export default {
  async created() {
    try {
      // process callback from auth provider
      const user = await oidcmgr.signinRedirectCallback();
      await store.dispatch("userUpdate", user);

      if (store.state.userData.isAuthenticated) {
        const nextTarget =
          user.state && user.state !== "/" ? user.state : defaultRootPage();

        try {
          await this.$router.push(nextTarget);
          // Return to avoid default case of navigating to Unauthorized
          return;
        } catch {
          /*
           * This catch is needed since vue router might throw if there's a navigation guard, see link below.
           * If you're still curious, try removing the try/catch and call this.$router.push("/");
           * https://stackoverflow.com/questions/5515310/is-there-a-standard-function-to-check-for-null-undefined-or-blank-variables-in
           * */
        }
      }
    } catch {
      // Supress error
    }
    await this.$router.push({ name: "Unauthorized" });
  },
};
</script>
