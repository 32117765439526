<template>
  <div class="row">
    <div class="col">
      <h1>Logga in</h1>
      <p>
        För att fortsätta krävs det att du är inloggad, klicka på knappen nedan
        för att komma till inloggningen.
      </p>

      <router-link to="signin" class="btn btn-primary" type="button"
        >Logga in</router-link
      >
    </div>
  </div>
</template>

<script>
import store from "@/services/store";
import util from "@/services/util";

export default {
  async created() {
    if (store.state.userData.isAuthenticated) {
      if (!util.userCanView()) {
        this.$router.push({ name: "noplanner" });
      } else if (util.isMobile()) {
        this.$router.push({ name: "mobile" });
      } else {
        this.$router.push({ name: "start" });
      }
    }
  },
};
</script>
